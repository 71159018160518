@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:700);

em {
	color: #232027;
}

.wrapper {
	width: 100%;
    margin-bottom: 10px;

}

div.gallery {
	margin-top: 5px;
	display: flex;
    justify-content: center;
}

div.gallery ul {
	list-style-type: none;
    list-style: none;
    margin: 0;
    padding: 0;
}

/* animation */
div.gallery ul li, div.gallery li img {
	-webkit-transition: all 0.1s ease-in-out;
  	-moz-transition: all 0.1s ease-in-out;
  	-o-transition: all 0.1s ease-in-out;
  	transition: all 0.1s ease-in-out;
}

div.gallery ul li {
	width: 50px;
	height: 50px;
	margin: 5px;
	padding: 5px;
	z-index: 0;
    display: inline-block;
    position: relative;
}

/* Make sure z-index is higher on hover */
/* Ensure that hover image overlapped the others */
div.gallery ul li:hover {
	z-index: 5;
}

/* Image is position nicely under li */
div.gallery ul li img {
	position: absolute;
	left: 0;
	top: 0;
	border: 1px solid #dddddd;
	padding: 3px;
    cursor: pointer;

	background: #f0f0f0;
    display: inline-block;
    height: 50px;
    width: 50px;
    backface-visibility: hidden;
}

div.gallery ul li img:hover {
    /* width: 100px;
    height: 100px;
    margin-top: -60px;
    margin-left: -44px;
    top: 65%;
    left: 65%; */
}

p.attribution {
	font-family: 'Consolas';
	color: #000;
	clear: both;
	text-align: center;
	line-height: 25px;
	padding-top: 30px;
}

p.attribution a {
	color: #4c8d7c;
}

/* Responsive hack */
@media only screen and (min-width: 499px) and (max-width: 1212px) {
	.wrapper {
		width: 500px;
	}
}

@media only screen and (max-width: 498px) {
	.wrapper {
		width: 300px;
	}

	div.gallery ul {
		list-style-type: none;
		margin: 0;
	}
}